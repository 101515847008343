import React from "react";
import "./home.css";
import Foto from "../../assets/Silas.jpg";
import { Header, Footer } from "src/components";

const Home = () => {
  return (
    <div className="container">
      <Header />
      <div>
        Man muss schon Sagen:
        <div className="foto">
          <img src={Foto} alt="Myself1" width="800" height="500" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
