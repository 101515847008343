import React from "react";

const Header = () => {
  return (
    <div className="container">
      <p className="solid">Das hier wird ein Header</p>
    </div>
  );
};

export default Header;
