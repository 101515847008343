// import { Routes, Route } from "react-router-dom";
import { Home } from "src/screens";

const Router = () => {
  return (
    <>
      <Home />
    </>
    // <>
    //   <Routes>
    //     <Route exact path="." element={<Home />} />
    //   </Routes>
    // </>
  );
};

export default Router;
