import { Typography } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <Typography>Ich bin ziemlich Sexy :D</Typography>
      <Typography>Hier kommt ein Footer</Typography>
    </div>
  );
};

export default Footer;
